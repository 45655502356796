import React from 'react';
import * as Icon from 'react-feather';
import { Link } from "gatsby";

const Features = ({ data }) => {
    return (
        <div className="boxes-area">
			<div className="container">
				<div className="section-title">
                    <h2>{data.title}</h2>
                    <div className="bar"></div>
                    <p>{data.text}</p>
                </div>

				<div className="row justify-content-center">
					{data.list.map((item, index) => {
						const IconComponent = Icon[item.icon];
						const additionalClass = item.cssClass || '';

						return (
							<div className="col-lg-3 col-sm-6" key={index}>
								<div className={`single-box ${additionalClass}`}>
									<div className="icon">
										<IconComponent />
									</div>

									<h3>
										<Link to={item.link.path}>
											{item.link.text}
										</Link>
									</h3>

									<p>{item.text}</p>
								</div>
							</div>
						)
					})}			
				</div>
			</div>
		</div>
    )
}

export default Features;
