import React from 'react';
import * as Icon from 'react-feather';

const OurFeatures = ({ data }) => {
    return (
        <div className="features-area pt-80 pb-50 bg-f7fafd">
            <div className="container">
                <div className="section-title">
                    <h2>{data.title}</h2>
                    <div className="bar"></div>
                    <p>{data.text}</p>
                </div>

                <div className="row justify-content-center">
                    {data.list.map((item, index) => {
                        const IconComponent = Icon[item.icon];
						const additionalClass = item.cssClass || '';

                        return (
                            <div className="col-lg-6 col-md-6" key={index}>
                                <div className="single-features">
                                    <div className={`icon ${additionalClass}`}>
                                        <IconComponent />
                                    </div>
                                    <h3>{item.title}</h3>
                                    <p>{item.text}</p>
                                </div>
                            </div>
                        )
                    })}
                </div>
            </div>
        </div>
    )
}

export default OurFeatures;  