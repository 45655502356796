import React from "react";
import Layout from "../components/_App/layout";
import Seo from "../components/_App/seo";
import Navbar from "../components/_App/Navbar";
import MainBanner from "../components/ITStartup/MainBanner";
import Features from "../components/ITStartup/Features";
import ServicesArea from "../components/ITStartup/ServicesArea";
import OurFeatures from "../components/ITStartup/OurFeatures";
import PartnerSmall from "../components/Common/PartnerSmall";
import Footer from "../components/_App/Footer";

const IndexPage = ({ pageContext: { lang, data }, location: { pathname } }) => (
    <Layout>
        <Seo
            title={data.seoTitle}
            lang={lang}
        />
        <Navbar
            currentPath={pathname}
            lang={lang}
        />
        <MainBanner data={data.banner} />
        <Features data={data.features} />
        <ServicesArea data={data.servicesArea} />
        <OurFeatures data={data.ourFeatures} />
        <PartnerSmall lang={lang} />
        <Footer lang={lang} />
    </Layout>
)

export default IndexPage;
