import React from 'react';
import ruData from '../../data/ru/componets/partner.json';
import enData from '../../data/en/componets/partner.json';

const Partner = ({ lang }) => {
    const data = lang === 'en' ? enData : ruData;
    return (
        <>
            <div className="ready-to-talk ready-to-talk-small">
                <div className="container">
                    <h3>{data.title}</h3>
                    <p>{data.text}</p>
                    
                    <a href={data.link} className="btn btn-primary">
                        {data.buttonText}
                    </a>
                </div>
            </div>
        </>
    )
 
}

export default Partner;  