import React from 'react';
import * as Icon from 'react-feather';

// Cloud Hosting Services Images
import FlowerTop from "../../assets/images/services-right-image/flower-top.png";
import Table from "../../assets/images/services-right-image/table.png";
import CercleShape from "../../assets/images/services-right-image/cercle-shape.png";

// Design & Development Images
import BigMonitor from "../../assets/images/services-left-image/big-monitor.png";
import Creative from "../../assets/images/services-left-image/creative.png";
import Developer from "../../assets/images/services-left-image/developer.png";
import SmallMonitor from "../../assets/images/services-left-image/small-monitor.png";
import SmallTop from "../../assets/images/services-left-image/small-top.png";
import Target from "../../assets/images/services-left-image/target.png";
import ServiceLeftMainPic from "../../assets/images/services-left-image/service-left-main-pic.png";

const ServicesArea = ({ data }) => {
    return (
        <>
            <div className="services-area ptb-80">
                <div className="container">
                    <div className="row h-100 justify-content-center align-items-center">
                        <div className="col-lg-6 col-md-12 services-left-image">
                            <img 
                                src={BigMonitor}
                                className="animate__animated animate__fadeInDown animate__delay-0.2s" 
                                alt="big-monitor"
                            />
            
                            <img 
                                src={Creative}
                                className="animate__animated animate__fadeInUp animate__delay-0.2s" 
                                alt="creative"
                            />
    
                            <img 
                                src={Developer}
                                className="animate__animated animate__fadeInLeft animate__delay-0.2s" 
                                alt="developer"
                            />
                    
                            <img 
                                src={FlowerTop}
                                className="animate__animated animate__fadeInLeft animate__delay-0.2s" 
                                data-wow-delay="0.6s" 
                                alt="flower-top"
                            />
        
                            <img 
                                src={SmallMonitor}
                                className="animate__animated animate__bounceIn animate__delay-0.2s" 
                                alt="small-monitor"
                            />
                        
                            <img 
                                src={SmallTop}
                                className="animate__animated animate__fadeInDown animate__delay-0.2s" 
                                alt="small-top"
                            />
                
                            <img 
                                src={Table}
                                className="animate__animated animate__zoomIn animate__delay-0.2s" 
                                alt="table"
                            />
            
                            <img 
                                src={Target}
                                className="animate__animated animate__fadeInUp animate__delay-0.2s" 
                                alt="target"
                            />
                        
                            <img 
                                src={CercleShape}
                                className="bg-image rotateme" 
                                alt="shape"
                            />
           
                            <img 
                                src={ServiceLeftMainPic}
                                className="animate__animated animate__fadeInUp animate__delay-0.2s" 
                                alt="main-pic"
                            />
                        </div>

                        <div className="col-lg-6 col-md-12 services-content">
                            <div className="section-title">
                                <h2>{data.title}</h2>
                                <div className="bar"></div>
                                <p>{data.text}</p>
                            </div>

                            <div className="row">
                                {data.list.map((item, index) => {
                                    const IconComponent = Icon[item.icon];

                                    return (
                                        <div className="col-lg-6 col-md-6" key={index}>
                                            <div className="box">
                                                <IconComponent /> {item.text}
                                            </div>
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ServicesArea;